<template>
  <div :class="`${className} info-head`">
    <div class="left-content">
      <div class="page-name ui-title-large">
        <slot name="pre-name-block"></slot>
        {{ pageName }}
      </div>
    </div>
    <div class="border-line"></div>
    <slot name="content-top"></slot>
  </div>
  <div v-if="hasBottomBlock" class="info-head">
    <slot name="content-bottom"></slot>
  </div>
</template>

<script src="./InfoHeadForPage.ts" lang="ts"/>
<style src="./InfoHeadForPage.scss" lang="scss"/>
