import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InfoHeadForPage',

  props: {
    pageName: {
      type: String,
      required: true
    },
    hasBottomBlock: {
      type: Boolean,
      default: true
    },
    className: {
      type: String,
      default: ''
    }
  }
});
