import { defineComponent, watch, computed } from 'vue';
import { useField } from 'vee-validate';
import InputNumber from 'primevue/inputnumber';
import BaseInlineMessage from '@/components/BaseComponents/BaseInlineMessage/BaseInlineMessage.vue';
import { NumberInput } from '@/types/eventTypes';

export default defineComponent({
  name: 'BaseInputNumber',

  components: {
    InputNumber,
    BaseInlineMessage
  },

  props: {
    className: {
      type: String
    },
    minFractionDigits: {
      type: Number,
      default: null
    },
    maxFractionDigits: {
      type: Number,
      default: null
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    value: {
      type: Number
    },
    name: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    step: {
      type: Number
    }
  },

  setup(props, { emit }) {
    const {
      errorMessage,
      value: inputValue,
      handleChange
    } = useField(props.name, undefined, { initialValue: props.value || 0 });

    watch(() => props.value, () => {
      handleChange(props.value);
    });

    watch(() => inputValue.value, () => {
      emit('input', inputValue.value);
    });

    const isMoreThenMax = (val: string | number) => {
      return props.max && (+val > props.max);
    };

    const isLessThenMin = (val: string | number) => {
      return props.min && (+val < props.min);
    };

    const inputFunction = (e: NumberInput) => {
      if (typeof e.value !== 'number') {
        return null;
      }
      if (props.max && isMoreThenMax(e.value)) {
        handleChange(props.max?.toFixed(props.maxFractionDigits));
        e.originalEvent.target.value = props.max.toString();
      } else if (props.min && isLessThenMin(e.value)) {
        handleChange(props.min?.toFixed(props.maxFractionDigits));
        e.originalEvent.target.value = props.min.toString();
      } else {
        handleChange(e.value.toFixed(props.maxFractionDigits || 0));
      }
    };

    const hasError = computed(() => {
      return (!!errorMessage.value && errorMessage.value.toString() !== ' ');
    });

    return {
      hasError,
      inputFunction,
      errorMessage,
      inputValue
    };
  }
});
