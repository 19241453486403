<template>
  <div class="p-field ui-input-field">
    <label
      v-if="label"
      :class="`${disabled && 'ui-disabled' } ${!!errorMessage && 'ui-error-label' }`"
      :for='`uid${name}`'
    >
      {{ label }}
    </label>
    <InputNumber
      incrementButtonClass="increment-btn"
      decrementButtonClass="decrement-btn"
      :minFractionDigits="minFractionDigits"
      :maxFractionDigits="maxFractionDigits"
      :id="`uid${name}`"
      :name="name"
      @input="inputFunction($event)"
      :modelValue="+inputValue"
      :placeholder="placeholder"
      :class="`${!!errorMessage && 'p-invalid ui-error-input'} ${className}`"
      :disabled="disabled"
      :min="min"
      :max="max"
      :step="step"
      showButtons
      locale="en-US"
      mode="decimal"
    />
    <BaseInlineMessage
      v-if="hasError"
      :message-text="errorMessage"
    />
  </div>
</template>

<script src="./BaseInputNumber.ts" lang="ts"/>
<style src="./BaseInputNumber.scss" lang="scss" />
